<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="
        bookmark-wrapper
        align-items-center
        flex-grow-1
        d-lg-flex
        header-navbar
        navbar-nav
      "
      style="margin-left: 5px; min-height: auto !important; background:transparent; !important"
    > 
     <h3 style="margin-bottom:0px">QA Review Tool</h3>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <user-dropdown />
    </b-navbar-nav>

    <b-modal
      id="password-modal"
      centered
      size="sm"
      hide-footer
      title="Unsaved Changes!"
    >
      Please enter your password

      <center>
        <b-form>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="password" label-for="password">
                <b-form-input id="password" v-model="userPassword" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="testfunc()"
          >
            <feather-icon icon="SaveIcon" />
            Submit
          </b-button>
        </b-form>
      </center>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";

import NotificationDropdown from "./components/NotificationDropdown.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserDropdown from "./components/UserDropdown.vue";
import Locale from "./components/Locale.vue";
import NOTIFY from "@/graphql/notification/subscription/SubscribeNotify.gql";
import EventBus from "@/event-bus.js";
import axios from "axios";
let ENDPOINT = process.env.VUE_APP_LAMBDA_CHANGE_USER_SITES;
const COMPANY_LOGO_API_ENDPOINT = process.env.VUE_APP_LAMBDA_PRESIGNED_PROFILE_IMG;
import { Auth } from "aws-amplify";
import GET_USER_INFO from "@/graphql/login/query/GetUserData.gql";
import { mapGetters, mapActions } from "vuex";
import {
  getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserProfileImage,
  getUserEmail,
} from "@/auth/utils";

export default {
  components: {
    BLink,
    BButton,
    BButtonGroup,
    BForm,
    BFormGroup,
    BFormInput,
    // Navbar Components
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,
    Locale,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      userPassword: "",
      notifications: [],
      currentSite: {},
      sites: [],
      siteLoaded: "",
      companyLogo:''
    };
  },
  apollo: {
    $subscribe: {
      notifications: {
        query: NOTIFY,
        variables: {
          recepient_id: localStorage.getItem("userId"),
        },
        result({ data }) {
          this.notifications = data.notifications;
          EventBus.$emit("notify", data.notifications);
        },
      },
    },
  },

  watch: {
    userSites(){
      if(this.site && this.site.site_name){
        this.sites = this.userSites.filter(site=>site.site.site_name != this.site.site_name)
      }
    },
    site(){
      if(this.userSites && this.userSites.length>0){
        this.sites = this.userSites.filter(site=>site.site.site_name != this.site.site_name)
      }
    }
  },

  computed: {
    ...mapGetters({
      company: "userSettings/getCompanyInfo",
      site: "userSettings/getSiteInfo",
      userSites: "userSettings/getUserSites"
    }),
  },

  methods: {
    ...mapActions({
      fetchUserAndCompanyInfo: "userSettings/fetchUserAndCompanyInfo",
      fetchSiteUserProfilePics: "profilePics/fetchSiteUserProfilePics",
    }),
    async reloginUser(site) {
      try{
        let siteID = site.id
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        if(cognitoUser && currentSession){
          EventBus.$emit("start-site-switch", site);
          const token = await localStorage.getItem("JoT");
          let result = await axios({
            method: "POST",
            url: ENDPOINT,
            headers: {
              Authorization: token.replace(/['"]+/g, ""),
            },
            data: {
              site_id: siteID,
              Authorization: token.replace(/['"]+/g, ""),
            },
          });
          if(result.status == 200){
            cognitoUser.refreshSession(
              currentSession.refreshToken,
              (err, session) => {
                if(err) throw err
                var { idToken, refreshToken } = session;
                localStorage.setItem("refreshToken", JSON.stringify(refreshToken.token));
                localStorage.setItem("JoT", JSON.stringify(idToken.jwtToken));
                this.fetchUserAndCompanyInfo()
                this.$router.go();
                setTimeout(() => {
                  EventBus.$emit("end-site-switch");
                }, 2000);
              }
            );
          }else if(result.status == 401){
            EventBus.$emit("end-site-switch");
            this.$toast.error({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                text: 'You are not authorized to perform this operation',
              },
            })
          }else{
            EventBus.$emit("end-site-switch");
            this.$toast.error({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                text: 'Error in switching sites. Login and try again',
              },
            })
          }
        }else{
          EventBus.$emit("end-site-switch");
          this.$toast.error({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              text: 'Error in switching sites. Login and try again',
            },
          })
          return
        }
      }catch(e){
        EventBus.$emit("end-site-switch");
        this.$toast.error({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            text: 'Error in switching sites. Login and try again',
          },
        })
      }
    },
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" >
.site-dropdown {
  color: #29335c !important;
  background-color: transparent;
}

.site-dropdown button {
  color: #052d41 !important;
  font-size: 14px;
  font-weight: 400 !important;
  padding: 0px;
  padding-left: 6px;
}

.site-dropdown button:hover {
  background-color: transparent !important;
}
.site-dropdown button:active {
  background-color: transparent !important;
}
.site-dropdown button:visited {
  background-color: transparent !important;
}
.site-dropdown button:focus {
  background-color: transparent !important;
}

.site-dropdown button {
  font-weight: 600;
}
</style>
